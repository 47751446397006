.AudioWorkstation {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgb(203, 203, 203);
  background-color: rgb(18,18,18);
}